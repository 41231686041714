import { render, staticRenderFns } from "./record.vue?vue&type=template&id=06fa669c"
import script from "./record.vue?vue&type=script&lang=js"
export * from "./record.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\工作\\项目\\智慧安全-2024\\sinoma-hse-web\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('06fa669c')) {
      api.createRecord('06fa669c', component.options)
    } else {
      api.reload('06fa669c', component.options)
    }
    module.hot.accept("./record.vue?vue&type=template&id=06fa669c", function () {
      api.rerender('06fa669c', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/business/datacenter/datacenter/programManage/record.vue"
export default component.exports